<template>
  <div class="maintenance pa-4 pa-sm-6">
    <div v-if="maintenance && maintenance.length != 0" class="bs-container text-center">
      <div class="bs-row">
        <div class="bs-col-md-7 mx-auto">
          <img
            src="@/assets/GAMEX LOGO FINAL 3-02.png"
            alt="GameX Logo"
            style="width: auto; height: 50px; margin: 0 auto 1.5rem auto"
          />
          <h1 class="text-center mb-4 mb-sm-6">
            {{ maintenance[0].title }}
          </h1>
          <p class="text-center">
            {{ maintenance[0].description }}
          </p>
          <p>—</p>
          <p class="mb-0">By Management</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRequestGet from "@/helpers/api_request_get.js";

export default {
  data: () => ({
    maintenance: [],
  }),
  mounted() {
    this.getMaintenance();
  },

  name: "Maintenance",
  methods: {
    async getMaintenance() {
      const res = await ApiRequestGet("", "maintenance");

      if (res.status == 200) {
        this.maintenance = res.data.records;
      }
    },
    getImgSrc(filename) {
      return require("@/assets/img/announcements/" + filename);
    },
  },
};
</script>

<style scoped>
.maintenance {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #06141c url("../assets/img/module_backgrounds/lucky-4-background.webp")
    no-repeat right bottom;
  background-size: cover;
}
</style>
